var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":_vm.data.id,"title":_vm.data.title,"ok-title":"Simpan","cancel-title":"Batal","title-tag":"h4","cancel-variant":"outline-danger","ok-variant":"info","no-close-on-backdrop":""},on:{"ok":function($event){$event.preventDefault();return _vm.validationForm()}}},[_c('validation-observer',{ref:"receiverForm"},[_c('b-form',[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Nama Lengkap","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","placeholder":"Nama Lengkap"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","placeholder":"Email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Topik","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.option,"placeholder":"Pilih Topik"},model:{value:(_vm.topik),callback:function ($$v) {_vm.topik=$$v},expression:"topik"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Jadwal Pengiriman","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.sending_options,"placeholder":"Pilih Jadwal Pengiriman"},model:{value:(_vm.sending_schedule),callback:function ($$v) {_vm.sending_schedule=$$v},expression:"sending_schedule"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.sending_schedule || _vm.data.type == 'edit')?_c('div',[(_vm.sending_schedule == 'Setiap Minggu')?_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Hari Pengiriman","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.day_sending_options,"placeholder":"Pilih Hari Pengiriman"},model:{value:(_vm.day_sending),callback:function ($$v) {_vm.day_sending=$$v},expression:"day_sending"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2408472224)})],1):_vm._e(),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Jam Pengiriman","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","placeholder":"Masukkan Jam Pengiriman"},model:{value:(_vm.time_sending),callback:function ($$v) {_vm.time_sending=$$v},expression:"time_sending"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2334388984)})],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }