<template>
  <b-card no-body class="h-100">
    <b-card-header>
      <b-card-title>Buletin</b-card-title>
      <!-- HELP ICON -->
      <feather-icon icon="HelpCircleIcon" size="20" class="mr-auto ml-1" />

      <!-- SEND BUTTON -->
      <b-button
        variant="outline-warning"
        class="ml-auto mr-1"
        @click="sendEmail"
      >
        <feather-icon icon="SendIcon" class="text-warning mr-05" />
        <span>Kirim Sekarang</span>
      </b-button>

      <!-- ADD NEW RECEIVER BUTTON -->
      <b-button
        variant="outline-primary"
        class="mr-1"
        @click="addReceiverEmail"
      >
        <feather-icon icon="PlusIcon" class="text-primary mr-05" />
        <span>Tambah Penerima Rutin</span>
      </b-button>

      <!-- FORM SEARCH -->
      <div class="w-25">
        <b-input-group class="input-group-merge">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" style="color: #7367f0 !important" />
          </b-input-group-prepend>
          <b-form-input
            placeholder="Cari"
            v-model="table_data.filter"
            style="height: 39px"
          />
        </b-input-group>
      </div>
    </b-card-header>
    <!-- TABLE COMPONENT -->
    <notifAndEmailTable
      :data="table_data"
      :editData="editData"
      :deleteData="deleteData"
      class="mb-2"
    />
    <div class="d-flex ml-2 mb-1">
      <b-badge pill variant="light-primary" class="mr-1">Topik 1</b-badge>
      <small><strong>Topik Media Sosial</strong></small>
    </div>
    <div class="d-flex ml-2 mb-1">
      <b-badge pill variant="light-secondary" class="mr-1">Topik 2</b-badge>
      <small><strong>Topik Media Konvensional</strong></small>
    </div>

    <!-- SEND EMAIL FORM MODAL -->
    <sendEmailModal :data="email_modal_data" />

    <!-- ADD RECEIVER MODAL -->
    <formReceiverModal :data="receiver_modal_data" />

    <!-- EDIT RECEIVER MODAL -->
    <formReceiverModal :data="edit_receiver_modal_data" />
  </b-card>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BButton,
  BBadge,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import notifAndEmailTable from "./notifAndEmailTable.vue";
import sendEmailModal from "./sendEmailModal.vue";
import formReceiverModal from "./formReceiverModal.vue";

export default {
  name: "cardBuletin",
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BButton,
    BBadge,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    ToastificationContent,
    notifAndEmailTable,
    sendEmailModal,
    formReceiverModal,
  },

  data() {
    return {
      email_modal_data: {
        title: "Kirim Email",
        type: "add",
        id: "send-email",
      },
      receiver_modal_data: {
        title: "Tambah Penerima Email",
        type: "add",
        id: "add-receiver-email",
      },
      edit_receiver_modal_data: {
        title: "Edit Penerima Email",
        type: "edit",
        id: "edit-receiver-email",
      },
      table_data: {
        type: "buletin",
        row: 5,
        filter: null,
        fields: [
          {
            key: "nama",
            label: "Nama",
            thClass: "text-left",
            tdClass: "text-left",
            sortable: true,
          },
          {
            key: "email",
            label: "email",
            thClass: "text-left",
            tdClass: "text-left",
            sortable: true,
            thStyle: { width: "20%" },
          },
          {
            key: "topik",
            label: "topik",
            thClass: "text-left",
            tdClass: "text-left",
            sortable: true,
            thStyle: { width: "20%" },
          },
          {
            key: "jadwal",
            label: "jadwal",
            thClass: "text-left",
            tdClass: "text-left",
            sortable: true,
            thStyle: { width: "20%" },
          },
          {
            key: "action",
            label: "Action",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "15%", paddingRight: "2rem" },
          },
        ],
        items: [
          {
            id: 1,
            nama: "Badai",
            email: "kabayan@co.id",
            topik: "Topik1",
            jadwal: "Setiap Hari Pukul 11.00",
          },
          {
            id: 2,
            nama: "Hallo",
            email: "kabayan@co.id",
            topik: "Topik1",
            jadwal: "Setiap Hari Pukul 11.00",
          },
          {
            id: 3,
            nama: "Bandung",
            email: "kabayan@co.id",
            topik: "Topik1",
            jadwal: "Setiap Hari Pukul 11.00",
          },
          {
            id: 4,
            nama: "Kabayan",
            email: "kabayan@co.id",
            topik: "Topik1",
            jadwal: "Setiap Hari Pukul 11.00",
          },
          {
            id: 5,
            nama: "Consulting",
            email: "kabayan@co.id",
            topik: "Topik1",
            jadwal: "Setiap Hari Pukul 11.00",
          },
        ],
      },
    };
  },

  methods: {
    makeToast(variant, message, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Berhasil",
            icon: "CheckCircleIcon",
            text: `${message}`,
            variant,
          },
        },
        {
          timeout,
        }
      );
    },
    sendEmail(id) {
      this.$bvModal.show("send-email");
    },
    addReceiverEmail(id) {
      this.$bvModal.show("add-receiver-email");
    },
    editData(id) {
      this.$bvModal.show("edit-receiver-email");
    },
    deleteData(id) {
      this.$swal({
        title: "Apakah Anda Yakin?",
        html: "<div class='text-secondary' style='font-size:12px'>Menghapus <strong>Group</strong> akan menghapus semua Topik  dan Keyword  yang ada didalamnya </div>",
        icon: "warning",
        customClass: "swal-wide",
        showCancelButton: true,
        cancelButtonText: "Batalkan",
        confirmButtonText: "Ya, Hapus !",
        focusCancel: true,
        reverseButtons: true,
        customClass: {
          cancelButton: "btn btn-danger mr-1 px-3",
          confirmButton: "btn btn-outline-info px-4",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.makeToast("success", "Group Telah Dihapus", 5000);
        }
      });
    },
  },
};
</script>