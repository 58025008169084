<template>
  <b-modal
    :id="data.id"
    :title="data.title"
    ok-title="Simpan"
    @ok.prevent="validationForm()"
    cancel-title="Batal"
    title-tag="h4"
    cancel-variant="outline-danger"
    ok-variant="info"
    no-close-on-backdrop
  >
    <validation-observer ref="sendEmailForm">
      <b-form>
        <!-- FULL NAME -->
        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Nama Lengkap"
            rules="required"
          >
            <b-form-input
              v-model="name"
              type="text"
              placeholder="Nama Lengkap"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- EMAIL -->
        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Email"
            rules="required|email"
          >
            <b-form-input v-model="email" type="text" placeholder="Email" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- TOPIK -->
        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Topik"
            rules="required"
          >
            <v-select
              v-model="topik"
              :options="option"
              placeholder="Pilih Topik"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- DATE SHORTCUT -->
        <div class="d-flex mb-1 mt-1 shortcut">
          <!-- TODAY -->
          <b-badge
            pill
            variant="light-primary"
            class="mr-1 clickable shortcut"
            @click="setToday()"
            >Hari Ini</b-badge
          >

          <!-- YESTERDAY -->
          <b-badge
            pill
            variant="light-primary"
            class="mr-1 clickable shortcut"
            @click="setYesterday()"
            >Kemarin</b-badge
          >

          <!-- LAST 7 DAY -->
          <b-badge
            pill
            variant="light-primary"
            class="clickable shortcut"
            @click="setOneWeekAgo()"
            >7 Hari Terakhir</b-badge
          >
        </div>

        <!-- START DATE -->
        <div class="row">
          <validation-provider
            #default="{ errors }"
            name="Tanggal Awal"
            rules="required"
            class="col-md-6"
          >
            <date-picker
              v-model="start_date"
              type="date"
              popup-class="rounded mt-1"
              input-class="input bg-transparent form-control w-100 clickable"
              placeholder="Pilih Tanggal Awal"
              format="DD MMM YYYY"
            >
              <!-- CUSTOM CALENDAR ICON -->
              <template #icon-calendar>
                <div>
                  <i
                    class="bi bi-calendar icon"
                    style="opacity: 0.5; margin-right: 6px"
                  ></i>
                </div>
              </template>
            </date-picker>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

          <!-- END DATE -->
          <validation-provider
            #default="{ errors }"
            name="Tanggal Akhir"
            rules="required"
            class="col-md-6"
          >
            <date-picker
              v-model="end_date"
              type="date"
              popup-class="rounded mt-1"
              input-class="input bg-transparent form-control w-100 clickable"
              placeholder="Pilih Tanggal Akhir"
              format="DD MMM YYYY"
            >
              <!-- CUSTOM CALENDAR ICON -->
              <template #icon-calendar>
                <div>
                  <i
                    class="bi bi-calendar icon"
                    style="opacity: 0.5; margin-right: 6px"
                  ></i>
                </div>
              </template>
            </date-picker>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </div>
        <div class="d-flex mt-1 text-muted">
          <feather-icon icon="AlertCircleIcon" size="20" />
          <small class="ml-05 text-justify mr-05">
            Pengiriman mungkin membutuhkan waktu hingga 5 menit agar sampai pada
            email tujuan.
          </small>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>
<script>
import {
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BBadge,
  VBModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";

export default {
  name: "sendEmailModal",
  components: {
    BModal,
    BForm,
    BFormInput,
    BInputGroup,
    BFormGroup,
    BInputGroupAppend,
    BBadge,
    vSelect,
    VBModal,
    ValidationProvider,
    ValidationObserver,
    DatePicker,
  },

  props: {
    data: Object,
  },

  data() {
    return {
      name: "",
      email: "",
      topik: "",
      start_date: "",
      end_date: "",
      option: ["Topik 1", "Topik 2"],
      required,
      email,
    };
  },

  methods: {
    validationForm() {
      this.$refs.sendEmailForm.validate().then((success) => {
        if (success) {
          this.$bvModal.hide(this.data.id);
        }
      });
    },
    setToday() {
      this.start_date = new Date();
      this.end_date = new Date();
    },
    setYesterday() {
      this.start_date = new Date();
      this.end_date = new Date();
      this.start_date.setDate(this.start_date.getDate() - 1);
      this.end_date.setDate(this.end_date.getDate() - 1);
    },
    setOneWeekAgo() {
      this.start_date = new Date();
      this.end_date = new Date();
      this.start_date.setDate(this.start_date.getDate() - 7);
    },
  },

  directives: {
    "b-modal": VBModal,
  },
};
</script>
<style scoped>
.shortcut :hover {
  opacity: 0.8 !important;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
