<template>
  <div>
    <!-- TANDAI KATA -->
    <cardTandaiKata />

    <!-- HIGHLIGHT POST -->
    <cardHighlightPost />

    <!-- BULETIN -->
    <cardBuletin />
  </div>
</template>
<script>
import cardTandaiKata from "@/components/setting/notification-email/cardTandaiKata.vue";
import cardHighlightPost from "@/components/setting/notification-email/cardHighlightPost.vue";
import cardBuletin from "@/components/setting/notification-email/cardBuletin.vue";

export default {
  name: "NotificationAndEmail",
  components: { cardTandaiKata, cardHighlightPost, cardBuletin },

  data() {
    return {
      title: "Notification & Email | Online Media Monitoring",
    };
  },

  created() {
    document.title = this.title;
  },
};
</script>