var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":_vm.data.id,"title":_vm.data.title,"ok-title":"Simpan","cancel-title":"Batal","title-tag":"h4","cancel-variant":"outline-danger","ok-variant":"info","no-close-on-backdrop":""},on:{"ok":function($event){$event.preventDefault();return _vm.validationForm()}}},[_c('validation-observer',{ref:"sendEmailForm"},[_c('b-form',[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Nama Lengkap","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","placeholder":"Nama Lengkap"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","placeholder":"Email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Topik","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.option,"placeholder":"Pilih Topik"},model:{value:(_vm.topik),callback:function ($$v) {_vm.topik=$$v},expression:"topik"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"d-flex mb-1 mt-1 shortcut"},[_c('b-badge',{staticClass:"mr-1 clickable shortcut",attrs:{"pill":"","variant":"light-primary"},on:{"click":function($event){return _vm.setToday()}}},[_vm._v("Hari Ini")]),_c('b-badge',{staticClass:"mr-1 clickable shortcut",attrs:{"pill":"","variant":"light-primary"},on:{"click":function($event){return _vm.setYesterday()}}},[_vm._v("Kemarin")]),_c('b-badge',{staticClass:"clickable shortcut",attrs:{"pill":"","variant":"light-primary"},on:{"click":function($event){return _vm.setOneWeekAgo()}}},[_vm._v("7 Hari Terakhir")])],1),_c('div',{staticClass:"row"},[_c('validation-provider',{staticClass:"col-md-6",attrs:{"name":"Tanggal Awal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker',{attrs:{"type":"date","popup-class":"rounded mt-1","input-class":"input bg-transparent form-control w-100 clickable","placeholder":"Pilih Tanggal Awal","format":"DD MMM YYYY"},scopedSlots:_vm._u([{key:"icon-calendar",fn:function(){return [_c('div',[_c('i',{staticClass:"bi bi-calendar icon",staticStyle:{"opacity":"0.5","margin-right":"6px"}})])]},proxy:true}],null,true),model:{value:(_vm.start_date),callback:function ($$v) {_vm.start_date=$$v},expression:"start_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('validation-provider',{staticClass:"col-md-6",attrs:{"name":"Tanggal Akhir","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker',{attrs:{"type":"date","popup-class":"rounded mt-1","input-class":"input bg-transparent form-control w-100 clickable","placeholder":"Pilih Tanggal Akhir","format":"DD MMM YYYY"},scopedSlots:_vm._u([{key:"icon-calendar",fn:function(){return [_c('div',[_c('i',{staticClass:"bi bi-calendar icon",staticStyle:{"opacity":"0.5","margin-right":"6px"}})])]},proxy:true}],null,true),model:{value:(_vm.end_date),callback:function ($$v) {_vm.end_date=$$v},expression:"end_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"d-flex mt-1 text-muted"},[_c('feather-icon',{attrs:{"icon":"AlertCircleIcon","size":"20"}}),_c('small',{staticClass:"ml-05 text-justify mr-05"},[_vm._v(" Pengiriman mungkin membutuhkan waktu hingga 5 menit agar sampai pada email tujuan. ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }