<template>
  <b-card no-body class="h-100">
    <b-card-header>
      <b-card-title>Tandai Kata</b-card-title>
      <!-- HELP ICON -->
      <feather-icon icon="HelpCircleIcon" size="20" class="mr-auto ml-1" />
    </b-card-header>
    <div class="d-flex align-items-center ml-2 mb-1">
      <!-- INPUT FORM -->
      <div style="width: 25%" class="mr-1">
        <b-form-input placeholder="Masukkan Kata" />
      </div>

      <!-- ADD WORD BUTTON -->
      <b-button variant="outline-primary">
        <feather-icon icon="PlusIcon" class="text-primary mr-05" />
        <span>Tambah Kata</span>
      </b-button>
    </div>
    <!-- FORM SEARCH -->
    <div class="ml-auto mb-1 mr-2 w-25">
      <b-input-group class="input-group-merge">
        <b-input-group-prepend is-text>
          <feather-icon icon="SearchIcon" style="color: #7367f0 !important" />
        </b-input-group-prepend>
        <b-form-input
          placeholder="Cari"
          v-model="table_data.filter"
          style="height: 39px"
        />
      </b-input-group>
    </div>
    <!-- TABLE COMPONENT -->
    <notifAndEmailTable
      :data="table_data"
      :editData="editData"
      :deleteData="deleteData"
      class="mb-2"
    />
  </b-card>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BFormInput,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import notifAndEmailTable from "./notifAndEmailTable.vue";

export default {
  name: "cardTandaiKata",
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BFormInput,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    ToastificationContent,
    notifAndEmailTable,
  },

  data() {
    return {
      table_data: {
        type: "markword",
        row: 5,
        filter: null,
        fields: [
          {
            key: "kata",
            label: "Kata",
            thClass: "text-left",
            tdClass: "text-left",
            sortable: true,
          },
          {
            key: "action",
            label: "Action",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "15%", paddingRight: "2rem" },
          },
        ],
        items: [
          {
            id: 1,
            kata: "Badai",
          },
          {
            id: 2,
            kata: "Hallo",
          },
          {
            id: 3,
            kata: "Bandung",
          },
          {
            id: 4,
            kata: "Kabayan",
          },
          {
            id: 5,
            kata: "Consulting",
          },
        ],
      },
    };
  },

  methods: {
    makeToast(variant, message, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Berhasil",
            icon: "CheckCircleIcon",
            text: `${message}`,
            variant,
          },
        },
        {
          timeout,
        }
      );
    },
    editData(id) {
      alert(id);
      // this.$bvModal.show("edit-receiver-email");
    },
    deleteData(id) {
      this.$swal({
        title: "Apakah Anda Yakin?",
        html: "<div class='text-secondary' style='font-size:12px'>Menghapus <strong>Group</strong> akan menghapus semua Topik  dan Keyword  yang ada didalamnya </div>",
        icon: "warning",
        customClass: "swal-wide",
        showCancelButton: true,
        cancelButtonText: "Batalkan",
        confirmButtonText: "Ya, Hapus !",
        focusCancel: true,
        reverseButtons: true,
        customClass: {
          cancelButton: "btn btn-danger mr-1 px-3",
          confirmButton: "btn btn-outline-info px-4",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.makeToast("success", "Group Telah Dihapus", 5000);
        }
      });
    },
  },
};
</script>