<template>
  <b-modal
    :id="data.id"
    :title="data.title"
    ok-title="Simpan"
    @ok.prevent="validationForm()"
    cancel-title="Batal"
    title-tag="h4"
    cancel-variant="outline-danger"
    ok-variant="info"
    no-close-on-backdrop
  >
    <validation-observer ref="receiverForm">
      <b-form>
        <!-- FULL NAME -->
        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Nama Lengkap"
            rules="required"
          >
            <b-form-input
              v-model="name"
              type="text"
              placeholder="Nama Lengkap"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- EMAIL -->
        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Email"
            rules="required|email"
          >
            <b-form-input v-model="email" type="text" placeholder="Email" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- TOPIK -->
        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Topik"
            rules="required"
          >
            <v-select
              v-model="topik"
              :options="option"
              placeholder="Pilih Topik"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- SENDING SCHEDULE -->
        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Jadwal Pengiriman"
            rules="required"
          >
            <v-select
              v-model="sending_schedule"
              :options="sending_options"
              placeholder="Pilih Jadwal Pengiriman"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- IF EDIT -->
        <div v-if="sending_schedule || data.type == 'edit'">
          <!-- SENDING DAY -->
          <b-form-group v-if="sending_schedule == 'Setiap Minggu'">
            <validation-provider
              #default="{ errors }"
              name="Hari Pengiriman"
              rules="required"
            >
              <v-select
                v-model="day_sending"
                :options="day_sending_options"
                placeholder="Pilih Hari Pengiriman"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- SENDING TIME -->
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Jam Pengiriman"
              rules="required"
            >
              <b-form-input
                v-model="time_sending"
                type="text"
                placeholder="Masukkan Jam Pengiriman"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>
<script>
import { BModal, BForm, BFormInput, BFormGroup, VBModal } from "bootstrap-vue";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";

export default {
  name: "formReceiverModal",
  components: {
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    vSelect,
    VBModal,
    ValidationProvider,
    ValidationObserver,
  },

  props: {
    data: Object,
  },

  data() {
    return {
      name: null,
      email: null,
      topik: null,
      sending_schedule: null,
      day_sending: null,
      time_sending: null,
      option: ["Opsi 1", "Opsi 2"],
      sending_options: ["Setiap Hari", "Setiap Minggu"],
      day_sending_options: [
        "Senin",
        "Selasa",
        "Rabu",
        "Kamis",
        "Jumat",
        "Sabtu",
        "Minggu",
      ],
      required,
      email,
    };
  },

  methods: {
    validationForm() {
      this.$refs.receiverForm.validate().then((success) => {
        if (success) {
          this.$bvModal.hide(this.data.id);
        }
      });
    },
  },

  directives: {
    "b-modal": VBModal,
  },
};
</script>